import React, { useState } from "react";

function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const hideMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="mobile">
      <div className={`menu-icon`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`mobile-menu  ${menuOpen ? "open" : ""}`}>
        <ul className={`menu-items ${menuOpen ? "open" : ""}`}>
          <li onClick={hideMenu}>
            <a href="#main">Home</a>
          </li>
          <li onClick={hideMenu}>
            <a href="#services">Services</a>
          </li>
          <li onClick={hideMenu}>
            <a href="#before-after">Before and After</a>
          </li>
          <li onClick={hideMenu}>
            <a href="#contact-us">Contact Us</a>
          </li>
          <li onClick={hideMenu}>
            <a href="#about">About</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MobileMenu;
