import logo from "./img/logo/logo.png";
import one_time_clean from "./img/services/deep-clean.jpg";
import move_in_out_clean from "./img/services/move-out-clean.jpg";
import recurring_clean from "./img/services/recurring-clean.jpg";
import deep_clean from "./img/services/one-time-clean.jpg";

import React, { useState, useEffect } from "react";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import "./App.css";
import MobileMenu from "./components/common/mobileMenu";

function IFrameSection(id, headerText, bodyText, iframeSrc) {
  return (
    <section className="section" id={id}>
      <div className="box-outer">
        <div className="box-inner">
          <h2 className="text-big">{headerText}</h2>
          <div className="iframe-container">
            <iframe
              title="Zenmaid Booking Form"
              sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation"
              height="2000"
              src={iframeSrc}
              frameborder="0"
              scrolling="no"
              className="iframe-form"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

function TextSection(id, headerText, bodyText) {
  return (
    <section className="section" id={id}>
      <div className="box-outer">
        <div className="box-inner">
          <h2 className="text-big">{headerText}</h2>
          <p
            className="text-small"
            dangerouslySetInnerHTML={{ __html: bodyText }}
          ></p>
        </div>
      </div>
    </section>
  );
}

function ListSection(id, headerText, bodyText, listItems) {
  const items = listItems.map((item) => (
    <li>
      <div className="list-item">
        <img src={item.image} alt="cleaning example"></img>
        <div>
          <h3>{item.title}</h3>
          <p
            className="text-small"
            dangerouslySetInnerHTML={{ __html: item.content }}
          ></p>
          <p>
            <span className="starting-at">Starting At:</span>
            <span className="price">{item.price}</span>
          </p>
        </div>
      </div>
    </li>
  ));

  return (
    <section className="section" id={id}>
      <div className="box-outer">
        <div className="box-inner">
          <h2 className="text-big">{headerText}</h2>
          <p
            className="text-small"
            dangerouslySetInnerHTML={{ __html: bodyText }}
          ></p>
          <ul className="inner-list">{items}</ul>
        </div>
      </div>
    </section>
  );
}

function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

function trimLastName(fullName) {
  // Split the full name into parts
  const nameParts = fullName.split(" ");

  // Extract the last name
  const lastName = nameParts.pop();

  // Trim the last name to only the first letter
  const trimmedLastName = lastName.length > 0 ? lastName[0] : "";

  // Concatenate the first name, middle names, and trimmed last name
  const trimmedFullName = [...nameParts, trimmedLastName].join(" ");

  return trimmedFullName;
}

const QuoteCarousel = ({ quotes, autoScrollInterval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Function to handle manual navigation to the next quote
  const nextQuote = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
  };

  // Function to handle manual navigation to the previous quote
  const prevQuote = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + quotes.length) % quotes.length
    );
  };

  // Effect to handle automatic scrolling
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextQuote();
    }, autoScrollInterval);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex, autoScrollInterval]);

  return (
    <div className="quote-carousel">
      <button className="quote-carousel-button" onClick={prevQuote}>
        &lt;
      </button>
      <div className="quote-container">
        <span className="quote">{quotes[currentIndex].quote}</span>
        <span className="author">
          - {trimLastName(quotes[currentIndex].author)}
        </span>
      </div>
      <button className="quote-carousel-button" onClick={nextQuote}>
        &gt;
      </button>
    </div>
  );
};

const mobile_size_cutoff = 620;
const images = importAll(require.context("./img", false, /\.(png|jpe?g|svg)$/));

var maxIndex = Object.keys(images).length / 2 - 1;
// console.log("images loaded: ", Object.keys(images).length);

const text = {
  "Cleaned By Amanda":
    "Cleaned by Amanda is a residential cleaning service supporting the Walla Walla valley. We are a small team that truly cares for our clients and treats their homes with the utmost care and respect. If you are interested in learning more about our services, please <a href='#contact-us'>contact us</a> for an estimate.",
  "Contact Us": `Interested in a quote? Have a question? Just want to say hello?
    Send us a message!
    <br />
    <br /> support@cleanedbyamanda.com
    <br />
    <a href="https://www.facebook.com/profile.php?id=100072335172603" target="_blank">
      Cleaned By Amanda - Facebook
    </a>
    <br /> <br />`,
  About: `Cleaned by Amanda is a labor of love conceived by owner, Amanda Lore, as a way to support her family and community. We are a woman-run, family-operated business that is dedicated to providing quality services and spreading kindness.`,
};

const serviceItems = [
  {
    title: "Deep Cleans",
    content:
      "A thorough cleaning of the full home or space performed before starting a recurring cleaning schedule, to prepare for an upcoming event, or as a way to reset and get things back on track.",
    image: deep_clean,
    price: "$200",
  },
  {
    title: "Move In/Out Cleans",
    content:
      "Extremely detailed cleaning.Top to bottom. Inside the oven, fridge, inside kitchen/bathroom cabinets. Done in an empty home before moving out or in.",
    image: move_in_out_clean,
    price: "$300",
  },
  {
    title: "Scheduled Recurring Cleans",
    content:
      "These cleans are scheduled at regular intervals. Weekly, Biweekly, or Monthly and are focused on high traffic areas, door knobs, light switches- most touched spaces. Most requested areas are floors, kitchens and bathrooms.",
    image: recurring_clean,
    price: "$100",
  },
  {
    title: "One-Time Clean",
    content:
      "These are a great way to get a feel for our work or just spruce things up.",
    image: one_time_clean,
    price: "$150",
  },
];

const quotes = [
  {
    quote:
      "Amanda is a very thorough cleaner. She does an awesome job and makes everything shine.  She takes pride in her work and it shows.",
    author: "Kimberly Rodighiero",
  },
  {
    quote: "I would definitely recommend. She is the best a cleaning my room.",
    author: "James Lore",
  },
  {
    quote:
      "Amanda takes care of my mothers house, and does an amazing job. My mom isn't easy to please, but she loves Amanda's work, and her personality. Highly recommend.",
    author: "Jeremy Ruchert",
  },
  {
    quote: "Very efficient, enjoyable people! They did a fabulous job!",
    author: "Kimberly Alger",
  },
  {
    quote:
      "Fabulous job, it is amazing to come home to a clean house. Great attention to detail! Amanda is very friendly and we are super happy we found her!",
    author: "Nancy Buttice",
  },
  {
    quote:
      "Amanda is awesome! She was so flexible with my schedule and my home sparkled when I returned! Her attention to detail is unmatched. Absolutely would recommend!",
    author: "Jaimee Knudson",
  },
  {
    quote:
      "I needed a move out clean and Amanda did an amazing job. Definetly went above and beyond for me!!!",
    author: "J Renea Silvan Garcia",
  },
  {
    quote:
      "Reliable, prompt, strong work ethic, honest, very professional and helpful.",
    author: "Kathryn Irwin Fisher",
  },
  {
    quote:
      "Amanda has cleaned 3 homes for me. She cleaned our home in Dayton while we lived there, she cleaned it for a move out, she cleaned our waitsburg home, and now our Walla Walla home! She is fabulous and I highly recommend her! I love coming home to a clean house. Thanks Amanda!!!",
    author: "Trista Flores",
  },
  {
    quote:
      "Amanda does amazing work. I love coming home to a clean house after she's been here",
    author: "Andrea Contreras",
  },
  {
    quote:
      "Amanda cleaned my house after I'd been away for 3 months. It was beautiful.  She pays close attention to details and gets all the corners. Now she's coming regularly. We'll worth the money.",
    author: "JanaLee Gueck-Wagner",
  },
  {
    quote:
      "I'm very happy with my recent clean! Impossible shower walls are much improved, tile floors are spotless, and my home just looks sparkly!",
    author: "Lynne Carpenter",
  },
  // {
  //   quote:
  //     "I hired Ryot Cleaners to deep clean my house, it looks absolutely amazing! My toilets look brand new, hire them to clean you won’t be disappointed! I’m definitely going to be a regular client.",
  //   author: "Heidi Lewis",
  // },
];

function App() {
  const [index, setIndex] = useState(0);
  // const [imgs, setImgs] = useState(images);

  function updateIndex(num) {
    // console.log("updateIndex", index, num);
    if (num && !isNaN(num)) {
      if (num > -1) {
        // console.log("index + num", index + num);
        // console.log("maxIndex", maxIndex);
        if (num + index <= maxIndex) {
          setIndex(index + num);
        }
      } else {
        if (index + num >= 0) {
          setIndex(index + num);
        }
      }
    }
    // console.log("index", index);
  }

  let size_style_dict = {
    maxHeight: "75vh",
    // maxHeight: "500px",
    // maxWidth: "500px",
    minHeight: "300px",
    // minWidth: "300px",
    width: "75vw",
  };

  if (window.innerWidth >= mobile_size_cutoff) {
    size_style_dict["minHeight"] = "500px";
    // size_style_dict["minWidth"] = "500px";
  }

  console.log("window.innerWidth", window.innerWidth);
  console.log("size_style_dict", size_style_dict);

  return (
    <div className="pink">
      <nav className="navbar background">
        <MobileMenu />
        <div>
          <img src={logo} alt="logo" className="logo"></img>
        </div>
        <ul className="nav-list">
          <li>
            <a href="#main">Home</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#before-after">Before and After</a>
          </li>
          <li>
            <a href="#contact-us">Contact Us</a>
          </li>
          <li>
            <a href="#booking">Book a Cleaning</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
        </ul>
      </nav>
      {TextSection("main", "Cleaned By Amanda", text["Cleaned By Amanda"])}

      <QuoteCarousel quotes={quotes} autoScrollInterval={10000} />

      {ListSection(
        "services",
        "Services",
        "Our services include but are not limited to:<br><br>",
        serviceItems
      )}
      <section className="section" id="before-after">
        <div className="box-outer">
          <div className="box-inner">
            <h2 className="text-big">Before and After</h2>
            <p className="text-small">
              Here is a set of before and after photos taken from various jobs
            </p>
            <div className="slider">
              <button type="button" onClick={() => updateIndex(-1)}>
                &lt;
              </button>
              <ReactCompareSlider
                itemOne={
                  <ReactCompareSliderImage
                    src={images["before_" + index + ".jpg"]}
                    alt="before"
                    style={size_style_dict}
                  />
                }
                itemTwo={
                  <ReactCompareSliderImage
                    src={images["after_" + index + ".jpg"]}
                    alt="after"
                    style={size_style_dict}
                  />
                }
                onPositionChange={() => {}}
                style={size_style_dict}
              />
              <button type="button" onClick={() => updateIndex(1)}>
                &gt;
              </button>
            </div>
          </div>
        </div>
      </section>
      {TextSection("contact-us", "Contact Us", text["Contact Us"])}
      {IFrameSection(
        "booking",
        "Book a Cleaning",
        "",
        "https://app.zenmaid.com/booking-forms/MX9A2/book"
      )}
      {TextSection("about", "About", text["About"])}
    </div>
  );
}

export default App;
